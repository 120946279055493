import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import logo from '../../assets/images/piclo.png';
import './Header.css';

const nav__links = [
  {
    path: '/',
    display: 'Home'
  },
  {
    path: '/about-us',
    display: 'About-us'
  },
  {
    path: '/medical',
    display: 'Medical Tours'
  },
  {
    path: '/tours',
    display: 'Top Rated Tours'
  },
  {
    path: '/mice',
    display: 'Mice'
  },
  {
    path: '/contact',
    display: 'Contact'
  },
  {
    path: '/education',
    display: 'Education-Trips'
  },
  {
    path: '/Group-visa',
    display: 'Group Visa'
  },
];

const Header = () => {
  const headerRef = useRef(null);
  const menuRef = useRef(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const stickyHeaderFunc = () => {
    window.addEventListener('scroll', () => {
      if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        headerRef.current.classList.add('sticky__header');
      } else {
        headerRef.current.classList.remove('sticky__header');
      }
    });
  };

  useEffect(() => {
    stickyHeaderFunc();

    return () => window.removeEventListener('scroll', stickyHeaderFunc);
  }, []);
  const toggleMenu = () => {
    menuRef.current.classList.toggle('show__menu');
    setMenuOpen(!menuOpen);
  };

  const handleContactClick = (event) => {
    event.preventDefault();
    toggleMenu();
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  return (
    <header className='header' ref={headerRef}>
      <Container>
        <Row>
          <div className='nav_wrapper d-flex align-items-center justify-content-between'>
            {/* ============ logo =============== */}
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            {/* ============ logo end =============== */}

            {/* ============ Menu start =============== */}
            <div className="navigation" ref={menuRef}>
              <ul className="menu d-flex align-items-center gap-5">
                {nav__links.map((item, index) => (
                  <li className="nav__item" key={index}>
                    {item.path === '/contact' ? (
                      <NavLink
                        to={item.path}
                        className={(navClass) => (navClass.isActive ? "active__link" : "")}
                        onClick={handleContactClick}
                      >
                        {item.display}
                      </NavLink>
                    ) : (
                      <NavLink
                        to={item.path}
                        className={(navClass) => (navClass.isActive ? "active__link" : "")}
                        onClick={toggleMenu}
                      >
                        {item.display}
                      </NavLink>
                    )}
                  </li>
                ))}
                <li className='nav__item'>
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} nav inNavbar>
                    <DropdownToggle caret nav>
                      Theme Tour
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <NavLink to='/tours/06' onClick={toggleMenu}>K-Beauty Tour </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink to='/tours' onClick={toggleMenu}>Seoul Wellness Tour </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink to='/tours/07' onClick={toggleMenu}>K-Pop</NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink to='/tours' onClick={toggleMenu}>K-Medical</NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>
              </ul>
            </div>
            {/* ============ Menu end =============== */}

            <div className="nav_right d-flex align-items-center gap-4">
              <div className="nav_btns d-flex align-items-center gap-4">
              <span
      className='mobile__menu'
      onClick={toggleMenu}
      style={{ position: 'relative', zIndex: 1000 }}  // Added z-index and position
    >
      {menuOpen ? (
        <>
        <span style={{ fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
          close
          <i className="ri-close-line" style={{ marginLeft: '8px' }}></i> {/* Add margin for spacing */}
        </span>
      </>
      ) : (
        <i className="ri-menu-line"></i>
      )}
    </span>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </header>
  );
};

export default Header;

