import React, { useEffect, useState } from 'react';
import CommonSection from '../shared/CommonSection';
import '../styles/Tour.css'
import TourCard from '../shared/TourCard';
// import Newsletter from '../shared/Newsletter';
import { Container, Row, Col } from 'reactstrap'
import tourData from '../assets/data/tours'



const Tour = () => {
  document.title = 'South Korea packages';

  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(0)



  useEffect(()=>{

    const pages = Math.ceil(5/8)
    setPageCount(pages)

  }, [page])

  return (
    <>
      <CommonSection title={"All Tours"}/>
      <section>
        <Container className='pt-0'>
          {
            <Row>
            {
              tourData?.map(tour=>
              <Col lg='3' md='6' sm='6' className='mb-4' key={tour._id}>
                <TourCard tour={tour}/>
              </Col>)
            }
            <Col lg='12'>
              <div className="pagination d-flex align-items-center justify-content-center mt-4 gap-3">
                {[...Array(pageCount).keys()].map(number => (
                  <span key={number} onClick={() => setPage(number)}
                  className={page === number ? "active__page" : ""}
                  >
                    {number + 1}
                  </span>
                ))}
              </div>
            </Col>
          </Row>
          }
        </Container>
      </section>
      {/* <Newsletter/> */}
    </>
  );
};

export default Tour;

