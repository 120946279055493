import React, { useRef, useState } from 'react'
import '../styles/TourDetails.css'
import { Container, Row, Col, Form, ListGroup, Button} from 'reactstrap'
import {useParams} from 'react-router-dom'
import tourData from '../assets/data/tours'
import calculateAvgRating from '../utils/AvgRating'
import avatar from "../assets/images/avatar.jpg"
// import Newsletter from '../shared/Newsletter'
import Booking from '../Components/Booking/Booking'



const TourDetails = () => {

  const {id} = useParams()
  const reviewMsgRef = useRef('')
  const [tourRating, setTourRating] = useState(null)

  //fetch data from database
  const tour = tourData.find(tour=> tour.id === id)


  // destructure properties from tour object 
  const {photo, title, desc, reviews, city, pdf} = tour

  const {totalRating, avgRating} = calculateAvgRating(reviews)
  
  // formate date
  const options = { day: "numeric", month: "long", year: "numeric" }

  // submit request to the server
  const submitHnadler = e => {
    e.preventDefault()


    // later we will call our api
  }
  return <>
  <section>
    <Container>
      <Row>
        <Col lg='8'>
          <div className='tour__content'>
            <img src={photo} alt="" />

            <div className="tour__info">
              <h2>{title}</h2>

              <div className="d-flex align-item-center gap-5">
              <span className='tour__rating d-flex align-items-center gap-1'>
                    <i className="ri-star-fill" style={{color:"var(--secondary-color)"}}></i>
                    {avgRating === 0 ? null : avgRating}
                    {totalRating === 0 ? ' (Not rated)' : `(${reviews.length})`}
              </span>
              </div>
              <div className="tour__extra-details d-flex justify-content-between align-items-center">
                <span>
                <i class="ri-map-pin-fill"></i>{city}
                </span>
                <Button className='btns third_btn' onClick={() => window.open(pdf, '_blank')}>Download itinerary</Button>
              </div>
              <h5>Description</h5>
              <p>{desc}</p>
              </div>
            {/* <embed src={pdf + "#toolbar=0"} type="application/pdf" width="100%" height="600px" /> */}
            <div className="iframe-container">
            <iframe
            src={`${pdf}#toolbar=0`}
            title="Tour PDF"
            className="frame"
            ></iframe>
            </div>
            {/* ========================= tour reviews section Starts ===================== */}
            <div className="tour__reviews mt-4">
              <h4>Reviews ({reviews?.length} reviews)</h4>

              <Form onSubmit={submitHnadler}>
                <div className='d-flex align-items-center gap-3 mb-4 rating__group'>
                  <span onClick={() => setTourRating(1)}>1<i class="ri-star-s-fill"></i></span>
                  <span onClick={() => setTourRating(2)}>2<i class="ri-star-s-fill"></i></span>
                  <span onClick={() => setTourRating(3)}>3<i class="ri-star-s-fill"></i></span>
                  <span onClick={() => setTourRating(4)}>4<i class="ri-star-s-fill"></i></span>
                  <span onClick={() => setTourRating(5)}> 5<i class="ri-star-s-fill"></i></span>
                </div>
                <div className="review__input">
                  <input type="text" ref={reviewMsgRef} placeholder='share your thoughts' required/>
                  <button className="btn primary__btn text-white" type='submit'>
                    Submit
                  </button>
                </div>
              </Form>
              <ListGroup className='user__reviews'>
                {
                  reviews?.map(review=>(
                    <div className="review__item">
                      <img src={avatar} alt="" />

                      <div className="w-100">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>Muhib</h5>
                          <p>
                            {
                            new Date('01-18-2023').toLocaleDateString("en-US", options)
                            }
                          </p>
                        </div>
                        <span className='d-flex align-items-center'>
                          5<i class="ri-star-s-fill"></i>
                        </span>
                      </div>
                      <h6>Amazing tour</h6>
                    </div>
                  ))
                }
              </ListGroup>
            </div>
            {/* ========================= tour reviews section Ends ===================== */}
          </div>
        </Col>
        <Col lg='4'>
          <Booking tour={tour} avgRating={avgRating}/>
        </Col>
      </Row>
    </Container>
  </section>
  {/* <Newsletter/> */}
  </>
}

export default TourDetails;