import React from 'react'
import '../styles/Home.css'
import { Container, Row, Col } from 'reactstrap'
import Sub from '../shared/Sub'
// import homeimg from '../assets/images/fside.png'
// import experienceImg from '../assets/images/experience.png'
// import ServiceList from '../Services/ServiceList'
import FeaturedTourList from '../Components/FeaturedTourList/FeaturedTourList'
import MassonyImagesGallery from '../Components/imageGallery/MassonyImageGallery'
import BasicSlider from './BasicSlider'
// import Testimonial from '../Components/Testimonials/Testimonials'
// import Newsletter from '../shared/Newsletter'

const Home = () => {
  document.title = 'Trip to korea';
  
  return (
    <>
    {/* ============== hero section start ===============*/}

    {/* <img src={homeimg} alt="/homeimg" className='back-video' /> */}
    <BasicSlider/>

    {/* ============== hero section End ===============*/}
    {/* <section>
      <Container>
        <Row>
          <Col lg="3">
            <h5 className="service__subtitle">What we serve</h5>
            <h2 className="service__title">We offer our best services</h2>
          </Col>
          <ServiceList/>
        </Row>
      </Container>
    </section> */}
    {/* ============== feature tour section starts ===============*/}
    <section>
      <Container>
        <Row>
          <Col lg="12" className="mb-5">
          <Sub subtitle={"Explore"}/>
          <h2 className='featured__tour-title'>Our featured tours</h2>
          </Col>
          <FeaturedTourList/>
        </Row>
      </Container>
    </section>
    {/* ============== feature tour section End ===============*/}
    {/* ============== experience section Starts ===============*/}
    {/* <section>
      <Container>
        <Row>
          <Col lg='6'>
            <div className="experience__content">
              <Sub subtitle={'Experience'}/>

              <h2>
                With our all experience <br /> we will serve you
              </h2>
              <p>
              With our extensive experience, we offer a Customization service that provides tailored solutions, personalized designs, and unique functionalities to perfectly meet your
                <br/>
                individual needs and ensure your complete satisfaction.
              </p>
            </div>

            <div className="counter__wrapper d-flex align-items-center gap-5">
              <div className="counter__box">
                <span>12k+</span>
                <h6>Successful trip</h6>
              </div>
              <div className="counter__box">
                <span>2k+</span>
                <h6>Regular clients</h6>
              </div>
              <div className="counter__box">
                <span>15</span>
                <h6>Years experience</h6>
              </div>
            </div>
          </Col>
          <Col lg='6'>
            <div className="experinece__img">
              <img src={experienceImg} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </section> */}
    {/* ============== experience section Ends ===============*/}
    {/* ============== gallery section Starts ===============*/}
    <section>
      <Container>
        <Row>
          <Col lg='12'>
            <Sub subtitle={"Gallery"}/>
            <h2 className="gallery__title">
              Visit our customers tour gallery
            </h2>
          </Col>
          <Col lg='12'>
          <MassonyImagesGallery/>
          </Col>
        </Row>
      </Container>
    </section>
    {/* ============== gallery section Ends ===============*/}
    {/* ============== testimonial section start ===============*/}
    {/* <section>
      <Container>
        <Row>
          <Col lg='12'>
            <Sub subtitle={"Fans Love"}/>
            <h2 className='testimonial__title'>Whats our fans say about us</h2>
          </Col>
          <Col lg='12'>
            <Testimonial/>
          </Col>
        </Row>
      </Container>
    </section> */}
    {/* ============== testimonial section Ends ===============*/}
    {/* <Newsletter/> */}
    </>
  )
}

export default Home



