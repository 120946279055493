import React from 'react';
import '../styles/BasicSlider.css';
import visaImage from '../assets/images/ghg.jpg';
import seoulBannerImage from '../assets/images/alphaone.jpg';
import bridgeImage from '../assets/images/alphatwo.jpg';
import nightlife from '../assets/images/alphathree.jpg';

const images = [
  { src: visaImage, alt: 'Boglisso' },
  { src: seoulBannerImage, alt: 'Monglo' },
  { src: visaImage, alt: 'Boglisso' },
  { src: bridgeImage, alt: 'Jungle' },
  { src: visaImage, alt: 'Boglisso' },
  { src: nightlife, alt: 'Jungle' },
];

const BasicSlider = () => {
  return (
    <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
      <div className="carousel-inner">
        {images.map((image, index) => (
          <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
            <img src={image.src} className="d-block w-100" alt={image.alt} />
          </div>
        ))}
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide="prev">
        <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide="next">
        <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default BasicSlider;



