import React from 'react'
import '../styles/Aboutus.css'
import certi from '../assets/images/certi.png'
import image from '../assets/images/llgs.png'
//import ac from '../assets/images/ac.png'
//import bc from '../assets/images/bc.png'
import Screen from '../assets/images/ffpage.png'
import Screen1 from '../assets/images/Screen1.png'
import Screen2 from '../assets/images/Screen2.png'
import Screen3 from '../assets/images/lpage.png'


const Aboutus = () => {
  document.title = 'About-us';
  return (
    <>
    <div className='aboutus-headings'>
        <img src={image} alt="silkroad" className='silklogos'/>
        {/* <img src={ac} alt="silkroad" className='logoss'/>
        <img src={bc} alt="silkroad" className='logos'/> */}
        <h3 className='headdings'>Mainly  based on South Korean ,Taiwan ,Thailand, China & Vietnam  market with the brand name  having own offices in SOUTH KOREA , INDIA , CHINA, BHUTAN & NEPAL with the objective to provide the best economical travels services to corporate & leisure market.</h3>
        <h3 className='headdings'>Silkroad India is the group of several companies with the professional quality in variety of services especially in the field of travel with experienced and well trained manpower.</h3>
    </div>
    <img src={Screen} alt="silkroad" className='Screens'/>
    <img src={Screen1} alt="silkroad" className='Screens'/>
    <img src={Screen2} alt="silkroad" className='Screens'/>
    <img src={Screen3} alt="silkroad" className='Screens'/>


    <img src={certi} alt="certificate" className='Screens'/>
    </>
  )
}

export default Aboutus
