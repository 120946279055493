import React from 'react'
import '../styles/Education.css'

const Education = () => {
    document.title = 'Education Trip korea';
  return (
    <>
    <div className='Edu-trip'>
    <h1 className='Education'>Education Trips</h1>
    <p className='Education2'>Educational travel in Korea is following the trajectory of so much else associated with Korea and is on a constant upward curve. Korean culture, music, football and pandemic response has been the envy of the world in recent years and consequently more and more visitors have been attracted to this stunning country. Plus there’s the incredible economic growth that has seen Korea move from poverty to one of the continent’s largest economies in just a few decades. Korea also ranks near the top across several educational metrics and all these factors combined make it both an enriching an inspiring place to visit. There’s plenty more to focus on too with a plethora of fun cultural options. Our student trips to Korea combine a mixture of the educational and the cultural. This blog explores some subject areas that are ideally suited to a trip to Korea.</p>
    <span className='Education3'>Business and Tech</span>
    <p className='Education2'>This is a subject area that appeals to economics and business students as they try and make sense of the remarkable success of Korean business in the last few decades. We arrange visits to a whole range of different business- from major manufacturing factories to tech start-ups.
        We aim to offer an insight into the business culture of South Korea. It has amongst the highest working hours in the world and it is this work ethic tied with the innovation that has enabled companies like Samsung and Hyundai to establish themselves as global pioneers. We’ll visit these companies and hear more about their success stories. You can also gain an insight into the future and the technical developments with visits to Samsung D-Light and SK Telecom HQ.  Samsung is constantly looking at cutting-edge ways to improve users’ experiences- they recently joined forces with Six Flags Theme Park to launch a range of virtual reality rollercoasters. Digitalisation has taken hold across the globe but nowhere is this tech savviness more evidenced than in Korea. 98% of 18–24-year-olds own a smart phone and the business culture is plugged in and future-focused.
        </p>
    <span className='Education3'>STEM</span>
    <p className='Education2'>We will arrange a school visit centred around STEM lessons as well as a number of other STEM-related activities. The highly interactive Gwacheon National Science Museum is a great way to gain an insight into some of the scientific developments. Korea has the quickest data in the world and hear more about how this has been achieved with a visit to a telecoms provider. We’ll also join in a coding class in Gangnam district and you can join Koreans who are trying to improve their skills to compete in the modern world.</p>
    <span className='Education3'>Culture</span>
    <p className='Education2'>The “Hallyu” refers to the Korean wave of culture that has swept the globe. It’s likely your students will have consumed some of the Korean cultural exports with the growing popularity of the K-Pop music scene, TV shows and Oscar-winning movies. Our trips take in some of these modern aspects but also explores the rich cultural heritage of Korea. One place which is great to do this in is Seoul’s Bukchon Hanok Village. You can explore some traditional housing and admire the exquisite gardens. Contrast this tranquillity with the urban excitement of some of Seoul’s more buzzing districts. If K-Pop is your thing – then you’ll love the SM Town COEX Artium. Check out the recording studios and hologram performances.
        We’ll also arrange some other cultural experiences such as a Korean language tutorial, a cooking class and a trip to see a Nanta Show. This is a hybrid of a physical comedy show and cooking class with hilarious results! We also arrange school visits which gives students the chance to see how Korean education works and for teachers to consider how this country has managed to have so much success in the PISA rankings. One other cultural experience is watching and trying out the art of Taekwondo!
        </p>
    <span className='Education3'>Sustainability</span>
    <p className='Education2'>We also run a number of service projects centred around sustainability projects. We run an upcycling project in Jumunjin where students will help with the processing of items and with the marketing strategy behind the project. They will also have the chance to speak with the people who run the programme and consider the challenges and support associated with green efforts in Korea. This unique opportunity will give a fresh insight into a growing issue and provide students with real hands-on experiences.</p>
    <h3 className='Education4'>History School Trip to South Korea</h3>
    <h2 className="days">Day 1: Seoul</h2>
    <p className="daysp">Your South Korea history school trip begins today! You’ll touch down in Seoul’s Incheon airport, greeted by one our wonderful Adventure Leaders. They’ll help you transfer to your accommodation and let you get settled in. Later, you’ll have an all-important trip briefing with your guide.
        It’s time to acquaint yourself with your new home! Your Adventure Leader will take you on a walking tour of your local area. They’ll point out local landmarks, give you tips on orientating yourself in the city, as well as giving you key advice on adjusting to Korea’s way of life.
        </p>
    <h2 className="days">Day 2: Seoul</h2>
    <ul>
        <p className="daysk">•	Get a view of the city unlike any other – from the second-highest point in Seoul! Head to the observation deck of the N Seoul Tower for some truly remarkable views.</p>
        <p className="daysk">•	Pay a visit to the iconic Gyeongbokgung, the main Royal Palace of the Joseon Dynasty. Explore the grounds, before heading uphill to the world-famous Bukchon Hanok village. This stunning area is the traditional heart of the city, so spend some time exploring the narrow rustic alleyways.</p>
        <p className="daysk">•	Today you’ll embark on a half-day private bus tour of the bustling city. You’ll visit key landmarks around Seoul, including Namsan Tower, the Lotte Tower, and the Gangnam District, with running commentary from your own personal local guide.</p>
    </ul>
    <h2 className="days">Day 3: DMZ</h2>
    <ul>
        <p className="daysk">•	This evening, you’ll watch a Nanta Show. These popular non-verbal comedy shows incorporate traditional samul nori rhythm from a percussion quartet, and involves acrobats, magic, and a good dose of audience participation!</p>
        <p className="daysk">•	You’ll learn about another key moment in Korean military history with a visit to the Gloucester Hill Battle Monument. Known in full as the British Army’s Seolmari Battle Monument in Paju, the monument commemorates the actions of the 1st Gloucestershire Regiment in 1951 during the Korean War. You’ll also visit Hill 235 in the same area, known as ‘Gloster Hill’, and learn about the actions of the Regiments during this period.</p>
        <p className="daysk">•	Two hours north of Seoul is the Demilitarized Zone (DMZ) between North and South Korea. On the visit today, head to the Dora Observatory, to see across the zone and into North Korea. You’ll also visit infiltration tunnels underground to learn about attempts to enter the South, as well as more about the area’s fascinating sociopolitical past.</p>
    </ul>
    <h2 className="days">Day 4: Seoul</h2>
        <p className="daysp">For the K-Pop fans in your group, Day 4 of your Korea history school trip kicks off with a bang.</p>
        <ul>
            <p className="daysk">•	You’ll attend a K-Pop dance class, taught by professional instructors! Students will learn routines that will help them perform like their idols.</p>
            <p className="daysk">•	Today you’ll also head to the HiKR Ground. Set up by the Korean Tourism Organization, it’s a home to all things K-wave. Filled with smart tech and entertainment spaces, it’s an interactive playground celebrating hallyu, the arts, and more.</p>
            <p className="daysk">•	If you’d not been musical enough today – it’s karaoke time! Head to a local space to belt out all your favourite hits.</p>
        </ul>
        <h2 className="days">Day 5: Gyeongju</h2>
    <ul>
        <p className="daysk">•	Today you’ll board a high-speed KTX train, bound for the next destination on your Korea history school trip – Gyeongju!</p>
        <p className="daysk">•	Explore the historic Cheonmachong Tomb, which is said to date back to the 5th century! Translating as ‘Sky Horse’ Tomb, it’s a typical upper class tomb of the Silla period – like those of kings – that consists of a wooden coffin inside an underground chamber. Upon its excavation, it’s thought that over 11,000 artefacts were removed from around the coffin itself.</p>
        <p className="daysk">•	Pay a visit to the stunning Gyeongju Donggung Palace, and the Wolji Pond – once a secondary palace used by the crown prince of the Silla Kingdom. It also served as a banquet site for important visitors and national events. It’s thought that the Pond dates all the way back to 674!</p>
    </ul>
    <h2 className="days">Day 6: Gyeongju</h2>
    <ul>
        <p className="daysk">•	Discover the fascinating history of the Bulguksa Temple, a UNESCO World Heritage Site. Located on the slopes of Mount Tohamsan, it’s the head temple of the Jogye Order of Korean Buddhism and is a designated Historic Site by the Korean government. It’s home to six of the National Treasures of South Korea, as well as two gilt-bronze statues of Buddha.</p>
        <p className="daysk">•	You then head to the site of your next, once-in-a-lifetime accommodation – the Golgulsa Temple. Located amid the natural beauty of Hamwolsan Mountain, you’ll be staying overnight in the temple in a stay unlike any other. Be sure to get an early night, because you’ll be up early in the morning for a meditation session.</p>
    </ul>
    <h2 className="days">Day 7: Busan</h2>
    <ul>
        <p className="daysk">•	Take part in a meditation session at the Golgulsa Temple, before boarding another KTX train to your final destination on the Korea history school trip – Busan!</p>
        <p className="daysk">•	Spend a wonderful hour on board a luxury yacht, as you take a boat cruise to enjoy some more of Busan. Along the way, you’ll see even more highlights of the metropolis, from Haeundae to Gwangalli Beach and Gwangan Bridge.</p>
        <p className="daysk">•	Learn all about the fascinating culture and history of the city with a private half-day city tour. You’ll see the most iconic sites, from the Gamcheon Culture Village to the Songdo Skywalk. Explore areas like BIFF Square and Jagalchi, getting to grips with the city as you learn from your very own local guide.</p>
    </ul>
    <h2 className="days">Day 8: Busan</h2>
    <ul>
    <p className="daysk">•	Pay a visit to the stunning Haedong Yonggungsa Temple – a beautiful building notably built on the coast instead of in the hills. Constructed in 1376 by the Buddhist teacher Naong during the Goryeo Dynasty, this amazing complex looks out to sea. Students can explore the Haesu Gwaneum Daebul (Seawater Great Goddess Buddha), Daeungjeon Main Sanctuary, Yongwangdang Shrine, Gulbeop Buddhist Sanctum, anda three story pagoda.</p>
    <p className="daysk">•	Board the high-speed KTX back to Seoul, ready to finish off your trip.</p>
    <p className="daysk">•	On your final evening, there’ll be a chance to take part in a reflection session. Here, your students will have time to digest their findings, consider the new ideas they’ve learned, and see how they’ve grown during their trip.</p>
    </ul>
    <h2 className="days">Day 9: Farewell</h2>
    <p className="daysp">Sadly, today marks the end of your Korea history school trip. You’ll head back to Incheon Airport, ready to transfer home.</p>
    <h4>South Korea STEM school trip, </h4>
    <p className="daysp">students get to grips with all things tech in both Seoul and Busan. From exploring museums and exhibitions to discussing rocket propulsion with leading researchers, they’ll get a well-rounded insight into the work being done to establish Korea as a technological leader. In addition, they’ll also spend some time exploring the vibrant communities and cultural hotspots.</p>
    </div>
    </>
  )
}

export default Education
