import galleryImg01 from '../../assets/images/devil.jpeg'
import galleryImg02 from '../../assets/images/devil2.webp'
import galleryImg03 from '../../assets/images/devil3.jpeg'
import galleryImg04 from '../../assets/images/devil4.jpeg'
import galleryImg05 from '../../assets/images/devil5.jpeg'
import galleryImg06 from '../../assets/images/devil6.jpeg'
import galleryImg07 from '../../assets/images/devil7.jpeg'
import galleryImg08 from '../../assets/images/devil8.jpeg'


const galleryImages = [
    galleryImg01,
    galleryImg07,
    galleryImg02,
    galleryImg06,
    galleryImg03,
    galleryImg04,
    galleryImg05,
    galleryImg08,
]
export default galleryImages
