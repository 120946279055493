import React, { useState, useEffect } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import './Booking.css';

const Booking = () => {
  const [fullName, setFullName] = useState("");
  const [guestSize, setGuestSize] = useState("");
  const [phone, setPhone] = useState("");
  const [bookAt, setBookAt] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  // Handle input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === 'fullName') setFullName(value);
    if (id === 'phone') setPhone(value);
    if (id === 'guestSize') setGuestSize(value);
    if (id === 'bookAt') setBookAt(value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:8000/api/v1/booking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fullName, guestSize, phone, bookAt }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to submit form');
      }
      console.log('Form submitted successfully:', data);
      // Navigate to the thank you page
      navigate('/thank-you');
    } catch (error) {
      console.error('Error submitting form:', error.message);
      setErrorMessage('Error submitting form: ' + error.message);
    }
  };

  // Placeholder data for avgRating and reviews
  const [avgRating, setAvgRating] = useState(0);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    // Fetch actual data for avgRating and reviews here
    // For example:
    // setAvgRating(4.5);
    // setReviews([review1, review2, ...]);
  }, []);

  return (
    <div className="bookings">
      <div className="booking__top d-flex align-items-center justify-content-between">
        <h3>Book Now</h3>
        <span className='tour__rating d-flex align-items-center'>
          <i className="ri-star-fill"></i>
          {avgRating === 0 ? null : avgRating} ({reviews?.length})
        </span>
      </div>
      {/*==================== Booking Form Starts ==================== */}
      <div className="booking__form">
        <h5>Information</h5>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <Form className='booking__info-form' onSubmit={handleSubmit}>
          <FormGroup>
            <input type="text" placeholder='Full Name' id='fullName' value={fullName} required onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <input type="number" placeholder='Phone' id='phone' value={phone} required onChange={handleChange} />
          </FormGroup>
          <FormGroup className='d-flex align-items-center gap-3'>
            <input type="date" id='bookAt' value={bookAt} required onChange={handleChange} />
            <input type="number" placeholder='Guest' id='guestSize' value={guestSize} required onChange={handleChange} />
          </FormGroup>
          <button type="submit" className="btn primary__btn">Submit</button>
        </Form>
      </div>
      {/*==================== Booking Form Ends ==================== */}
    </div>
  );
};

export default Booking;

