import React from 'react'
import '../styles/GroupVisa.css'
import visa1 from '../assets/images/A31.png'
import visa3 from '../assets/images/A22.png'
import visa2 from '../assets/images/apage.png'
import visa4 from '../assets/images/A3.png'
import visa5 from '../assets/images/A4.png'


const GroupVisa = () => {
  document.title = 'Group visa for south korea';
  
  return (
    <div className='Groupvisa'>
        <h1>GROUP VISA</h1>
        <p>Group visa for south korea</p>
    <img src={visa1} alt="silkroad" className='visaScreen'/>
    <img src={visa3} alt="silkroad" className='visaScreen'/>
    <img src={visa2} alt="silkroad" className='visaScreen'/>
    <img src={visa4} alt="silkroad" className='visaScreen'/>
    <img src={visa5} alt="silkroad" className='visaScreen'/>
    </div>
  )
}

export default GroupVisa
