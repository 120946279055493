import React from 'react';
import './Footer.css';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import image from '../../assets/images/lkg.png';
import quickLinkImage1 from '../../assets/images/ac.png';
import quickLinkImage2 from '../../assets/images/bc.png';

const quick__links = [
  {
    path: '/',
    display: 'Home'
  },
  {
    path: '/medical',
    display: 'Medical Tours'
  },
  {
    path: '/tours',
    display: 'Top Rated Tours'
  },
  {
    path: '/mice',
    display: 'Mice'
  },
  {
    path: '/about-us',
    display: 'About-us'
  },
];

const quick__links2 = [
  {
    path: '/contact',
    display: 'Contact'
  },
  {
    path: '/education',
    display: 'Education-Trips'
  },
  {
    path: '/Group-visa',
    display: 'Group Visa'
  },
];

const Footer = () => {
  const handleContactClick = (event) => {
    event.preventDefault();
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  return (
    <footer className="footer" id='footer'>
      <Container>
        <Row>
          <Col lg='4'>
            <div className="pogo">
              <img src={image} alt="" />
              <h5 className="footer__link-maintitle">Korea Office</h5>
              <p># 715, Jongn Officetel, 21, Samil-daero 30-gil, Jongno-gu,
                Seoul, Republic of Korea 03132, T: +82-10-2948-1895
              </p>
              <h5 className="footer__link-maintitle">India Office</h5>
              <p>H2-204 &205, Apra North Ex Plaza Netaji Subhash Place, Pitampura 
                New Delhi-110034 (India) T: +91-11-9868708176, 9818358158
                E-Mail: silkroad_india@hotmail.com
              </p>
              <div className="social__links d-flex align-items-center gap-4">
                <span>
                  <Link to='#'><i className="ri-youtube-line"></i></Link>
                </span>
                <span>
                  <Link to='#'><i className="ri-facebook-circle-line"></i></Link>
                </span>
                <span>
                  <Link to='#'><i className="ri-instagram-line"></i></Link>
                </span>
              </div>
            </div>
          </Col>
          <Col lg='4'>
            <h5 className='footer__link-title'>Discover</h5>
            <div className='footer__quick-links'>
              {quick__links.map((item, index) => (
                <div key={index} className='footer__link-item'>
                  <Link to={item.path}>{item.display}</Link>
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              <img src={quickLinkImage1} alt="Quick Link 1" className="quick-link-image1" />
            </div>
          </Col>
          <Col lg='4'>
            <h5 className='footer__link-title'>Quick Links</h5>
            <div className='footer__quick-links'>
              {quick__links2.map((item, index) => (
                <div key={index} className='footer__link-item'>
                  {item.path ? (
                    item.path === '/contact' ? (
                      <Link to={item.path} onClick={handleContactClick}>{item.display}</Link>
                    ) : (
                      <Link to={item.path}>{item.display}</Link>
                    )
                  ) : (
                    null
                  )}
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              <img src={quickLinkImage2} alt="Quick Link 2" className="quick-link-image" />
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;



