import React from 'react'
import '../styles/Medical.css'
import med from '../assets/images/med.jpg'

const Medical = () => {
  document.title = 'Medical Tourism in Korea';
  return (
    <div className='medjust'>
      <h1 className='mh1'>Medical Tourism in Korea</h1>
      <img className='medimg' src={med} alt="" />
      
      <section>
        <h3 className="second">Korea: A Leading Global Destination for Medical Tourism</h3>
        <p className='Medp'>
          Korea has established itself as a global leader in medical tourism, offering a range of compelling reasons to choose it as your preferred destination. With extensive clinical trial experience, advanced medical technology, competitive prices, rapid and efficient diagnostic capabilities, and a sophisticated IT-based medical infrastructure, Korea provides an exceptional healthcare experience. The country boasts highly skilled medical professionals who deliver world-class treatments and services, ensuring that patients receive the best care available.
        </p>
      </section>
      
      <section>
        <h3 className="second">Korea: Pioneering the Future of Medicine</h3>
        <p className='Medp'>
          South Korea, known for its prowess in the IT industry and the influential Hallyu (Korean wave) cultural phenomenon spanning fashion, food, K-pop, and K-drama, has also emerged as a leader in medical tourism. The nation’s capacity to lead extends to the medical sector, with cutting-edge medical equipment, smart IT-driven medical technologies, state-of-the-art hospitals, and innovative treatments. Korea has become a hub of medical excellence, nurturing a new generation of highly trained doctors.
        </p>
      </section>
      
      <section>
        <h3 className="second">Reliable and Comprehensive Medical Tour Services</h3>
        <p className='Medp'>
          To ensure a seamless medical tourism experience, professional medical tour agencies registered with the Ministry of Health and Welfare in Korea provide reliable services. From airport pickups to hospitalization, treatments, surgeries, and guided tours, these agencies cater to every aspect of your journey. The Korean government, committed to enhancing patient satisfaction, runs an international medical tour coordinator license program.
        </p>
      </section>
      
      <section>
        <h3 className="second">Global Recognition and Collaboration</h3>
        <p className='Medp'>
          Korea’s dedication to excellence has not gone unnoticed. It has received the prestigious “Health and Medical Tourism: Destination of the Year” award for two consecutive years, acknowledging its commitment to providing outstanding healthcare services. Additionally, medical professionals from 32 countries worldwide have completed specialized training programs in Korea, fostering collaboration and knowledge exchange.
        </p>
      </section>
      
      <section>
        <h3 className="second">Excellence in Medical Treatments</h3>
        <p className='Medp'>
          Korea is at the forefront of advanced medical treatments, particularly in the fields of cancer treatment and organ transplantation technologies. The country’s medical infrastructure ranks among the world’s best in the adoption of electronic medical record (EMR) systems, ensuring accurate and efficient healthcare delivery.
        </p>
      </section>
      
      <section>
        <h3 className="second">Reliability and Accreditation</h3>
        <p className='Medp'>
          Korea maintains a Registration System for Medical Institutions Serving Foreign Patients, providing transparency and reassurance to international patients seeking medical treatment. The Korean Accreditation Program for Hospitals Serving Foreign Patients (KAHF) further enhances the credibility of medical institutions in Korea, guaranteeing quality care and services.
        </p>
      </section>
      
      <section>
        <h3 className="second">Convenience and Support</h3>
        <p className='Medp'>
          To facilitate a smooth medical tourism experience, Korea offers a dedicated Medical Tourism Information Center. This resource hub provides comprehensive information and support, assisting patients with inquiries, appointments, and guidance throughout their medical journey.
        </p>
      </section>
    </div>
  )
}

export default Medical
