import './App.css';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Routers from './routes/Routers';
import useScrollToTop from './Hook/useScrollTop';
import whatsapplogo from './assets/images/ss.png'


function App() {
  useScrollToTop()
  
  return (
    <>
    <Header/>
    <Routers/>
    <Footer/>
    <div className="fixed-bottom right-100 p-3">
    <a href='https://wa.me/919868708176?'target='_blank'>
      <img src={whatsapplogo} width="60" alt="whatsapp" />
    </a>
  </div>
    </>
  );
}

export default App;
