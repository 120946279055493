import React from 'react'
import {Routes, Route} from 'react-router-dom'
import Home from '../pages/Home'
import Medical from '../pages/Medical'
import Mice from '../pages/Mice'
import Education from '../pages/Education'
import Tour from '../pages/Tour'
import TourDetails from '../pages/TourDetails'
import Thankyou from '../pages/Thankyou'
import Aboutus from '../pages/Aboutus'
import GroupVisa from '../pages/GroupVisa'
import Blog01 from '../pages/Blog01'
import Blog03 from '../pages/Blog03'
import Blog02 from '../pages/Blog02'


const Routers = () => {
  return (
    <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/medical' element={<Medical/>}/>
        <Route path='/mice' element={<Mice/>}/>
        <Route path='/education' element={<Education/>}/>
        <Route path='/tours' element={<Tour/>}/>
        <Route path='/tours/:id' element={<TourDetails/>}/>
        <Route path='/thank-you' element={<Thankyou/>}/>
        <Route path='/about-us' element={<Aboutus/>}/>
        <Route path='/Group-visa' element={<GroupVisa/>}/>
        <Route path='/Blog01' element={<Blog01/>}/>
        <Route path='/Blog02' element={<Blog02/>}/>
        <Route path='/Blog03' element={<Blog03/>}/>
    </Routes>
  )
}

export default Routers
