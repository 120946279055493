import tourImg01 from "../images/seoul_new.jpeg";
// import tourImg02 from "../images/namo.jpg";
import tourImg02 from "../images/nami_island.jpeg";
// import tourImg03 from "../images/jeju.jpg";
import tourImg03 from "../images/gyeongju.jpeg";
// import tourImg04 from "../images/bbusan.jpg";
import tourImg04 from "../images/busan.jpeg";
// import tourImg05 from "../images/kidstour.jpg";
import tourImg05 from "../images/kids_seoul.jpeg";
// import tourImg06 from "../images/kbeuty.webp";
import tourImg06 from "../images/k_beauty.jpeg";
// import tourImg07 from "../images/k-pop.jpg";
import tourImg07 from "../images/k_pop.jpeg";
import Travel_itinerary from "../images/seol.pdf"
import Nami_itinerary from "../images/nomi.pdf"
import jeju_itinerary from "../images/jeju.pdf"
import Busab_itinerary from "../images/Busan.pdf"
import kids_itinerary from "../images/kids.pdf"
import kbeauty_itinerary from "../images/k-beuty.pdf"
import school_itinerary from "../images/school.pdf"



const tour = [
  {
    id: "01",
    title: "SPARKLING SEOUL TOUR 4N & 5D",
    city: "SEOUL",
    distance: 300,
    address: "Somewhere",
    price: 99,
    maxGroupSize: 10,
    desc: "Experience the best of Seoul with our Sparkling Seoul Tour 4N & 5D. Explore iconic spots like N Seoul Tower, Gyeongbokgung Palace, and Myeongdong. Enjoy local cuisine, rich history, and vibrant city life in this unforgettable South Korea getaway. Book now for a perfect mix of culture and adventure!",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg01,
    featured: true,
    pdf: Travel_itinerary,
  },
  {
    id: "02",
    title: "SEOUL TOUR WITH NAMI ISLAND",
    city: "NAMI ISLAND",
    distance: 400,
    address: "Somewhere",
    price: 99,
    maxGroupSize: 8,
    desc: "Discover the beauty of South Korea with our Seoul Tour with Nami Island. Explore Seoul's top landmarks like Gyeongbokgung Palace and N Seoul Tower, followed by a serene escape to the picturesque Nami Island. Enjoy a perfect blend of city excitement and natural beauty in this unforgettable tour. Book now for an incredible South Korean experience!",
    reviews: [
      
    ],
    avgRating: 4.5,
    photo: tourImg02,
    featured: true,
    pdf: Nami_itinerary,
  },
  {
    id: "03",
    title: "SEOUL JEJU BUSAN GYEONG JU TOUR",
    city: "JEJU ISLAND",
    distance: 400,
    address: "Somewhere",
    price: 99,
    maxGroupSize: 8,
    desc: "Explore the best of South Korea with our Seoul, Jeju, Busan, and Gyeongju Tour. Visit Seoul's vibrant cityscape, the natural wonders of Jeju Island, the coastal charm of Busan, and the historical treasures of Gyeongju. Experience a diverse mix of culture, nature, and history on this unforgettable journey across South Korea. Book now for a comprehensive South Korean adventure!",
    reviews: [
      
    ],
    avgRating: 4.5,
    photo: tourImg03,
    featured: true,
    pdf: jeju_itinerary,
  },
  {
    id: "04",
    title: "SEOUL TOUR WITH BUSAN",
    city: "BUSAN",
    distance: 400,
    address: "Somewhere",
    price: 99,
    maxGroupSize: 8,
    desc: "Experience the best of South Korea with our Seoul Tour with Busan. Explore the vibrant culture and attractions of Seoul, then head to Busan for stunning beaches, coastal temples, and dynamic city life. This tour offers the perfect blend of modern city exploration and seaside relaxation. Book now for an exciting South Korean adventure!",
    reviews: [
      
    ],
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
    pdf: Busab_itinerary,
  },
  {
    id: "05",
    title: "KIDS SPECIAL SEOUL CITY TOUR 3N & 4D",
    city: "SEOUL",
    distance: 400,
    address: "Somewhere",
    price: 99,
    maxGroupSize: 8,
    desc: "Make unforgettable memories with our Kids Special Seoul City Tour 3N & 4D. Designed for families, this tour includes kid-friendly attractions like Lotte World, interactive museums, and scenic parks. Explore Seoul’s top sights while ensuring fun and entertainment for the whole family. Book now for a perfect family getaway in South Korea!",
    reviews: [
      
    ],
    avgRating: 4.5,
    photo: tourImg05,
    featured: true,
    pdf: kids_itinerary,
  },
  {
    id: "06",
    title: "K-BEAUTY TOUR 4 NIGHT & 5 DAYS",
    city: "MYEONGDONG",
    distance: 400,
    address: "Somewhere",
    price: 99,
    maxGroupSize: 8,
    desc: "Immerse yourself in South Korea’s beauty culture with our K-Beauty Tour 4 Night & 5 Days. Discover the secrets behind Korean skincare and cosmetics through visits to top beauty hubs, exclusive spa treatments, and shopping at premium K-beauty stores. Perfect for beauty enthusiasts seeking the ultimate self-care experience in Seoul. Book now for a rejuvenating beauty adventure!",
    reviews: [
      
    ],
    avgRating: 4.5,
    photo: tourImg06,
    featured: true,
    pdf: kbeauty_itinerary,
  },
  {
    id: "07",
    title: "SCHOOL TRIP: CULTURE AND K-POP",
    city: "SEOUL",
    distance: 400,
    address: "Somewhere",
    price: 99,
    maxGroupSize: 8,
    desc: "Join our exciting School Trip: Culture and K-Pop package for a fun and educational experience in South Korea. Discover the vibrant Korean culture through interactive workshops, historical site visits, and thrilling K-pop dance sessions. Perfect for students who want to explore South Korea’s cultural richness and the global phenomenon of K-pop. Book now for a school trip they’ll never forget!",
    reviews: [
      
    ],
    avgRating: 4.5,
    photo: tourImg07,
    featured: true,
    pdf: school_itinerary,
  },
];

export default tour;
