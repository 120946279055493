import React from 'react'
import '../styles/Mice.css'
import pic1 from '../assets/images/pic1.jpg'
import pic2 from '../assets/images/pic2.jpg'
import pic3 from '../assets/images/pic3.jpg'


const Mice = () => {
    document.title = 'Mice';
  return (
    <div className="mice-container">
            <h1 className='head1'>MICE - Meetings, Incentives Travel, Conventions, and Exhibitions/Events.</h1>
            
            <div className="section">
                <h3 className='heading3'>Meetings</h3>
                <p className="bigline">Meetings refers to various meetings held for the purposes of exchanging ideas, discussion, information exchange, and establishing social networks. It includes meetings which do not satisfy requirements for a convention, but have a minimum of 10 total participants and are held at official meeting facilities, hotels, and condominiums for a minimum of 4 hours.</p>
                <ul>
                    <li>Distinguished by hosting organization and key participants</li>
                    <li>Association/Society Meetings: Meetings held by Associations/Societies</li>
                    <li>Government/Public Meetings: Meetings held by Government/Public Organizations</li>
                    <li>Corporate Meetings: Corporate Incentives Meetings are included in Corporate Meetings</li>
                    <li>Other Meetings: Meetings which do not fall into the above categories</li>
                    <li>Distinguished by participant nationality and number of participating countries</li>
                    <li>International Meetings: Meetings with over 10 foreign participants in attendance (over 4 hours)</li>
                    <li>Domestic Meetings: Meetings with less than 10 foreigners in attendance (over 4 hours)</li>
                </ul>
                {/* <img src={fc} alt="" className="img" /> */}
                <img className='img' src={pic1} alt="" />
                <img className='img' src={pic2} alt="" />
                <img className='img' src={pic3} alt="" />
            </div>

            <div className="section">
                <h3 className='heading3'>INCENTIVES TRAVEL</h3>
                <p className="bigline">Incentives Travel refers to purely incentive travel and incentive travel meetings awarded to the organization members on their achievements, with more than 10 foreign inbound participants, staying at a local accommodation for a minimum of 1 night.International Incentives: Incentive travel or incentive meetings with over 10 foreign participants in attendance (staying a minimum of 1 night at a designated accommodation facility)</p>
            </div>

            <div className="section">
                <h3 className='heading3'>CONVENTIONS</h3>
                <p className="bigline">Conventions refers to various meetings held for the purposes of exchanging ideas, discussion, information exchange, and establishing social networks. It includes meetings held by government and public organizations or associations and corporations which have a minimum of 10 foreign participants with a total of 250 participants, held at official meeting facilities, hotels, and condominiums for a minimum of 4 hours.
                    Distinguished by hosting organization and key participants
                </p>
            </div>

            <div className="section">
                <h3 className='heading3'>EXHIBITIONS / EVENTS</h3>
                <p className="bigline">Exhibitions refers to various exhibitions held for the purpose of conducting sales, promotion, and marketing activities targeted towards distributors, traders, consumers, and the general public. As an exhibition according to the Exhibition Industry Development Act, it includes trade exhibitions, consumer exhibitions and combined exhibitions which last for more than one day.
                    Distinguished by Type
                </p>
            </div>
        </div>
  )
}

export default Mice
